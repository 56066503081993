body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 62.6%;
  height: 100vh;
  /* overflow: hidden !important; */
}

::selection {
  background: rgb(240, 124, 130);
}

#root {
  height: 100%;
  height: 100%;
}

.App {
  /* z-index: -1; */
  /* background-color: rgba(0, 0, 0, 0.4); */
  height: 100%;
}

::-webkit-scrollbar {
  width: 0 !important;
}

* {
  /* color: rgb(230, 210, 213); */
  /* color: rgb(192, 196, 195); */
  /* color: rgb(181, 170, 144); */
  /* color: rgb(249, 241, 219); */
  color: rgb(251, 236, 222);
}

.ant-modal-header {
  background: transparent !important;
}

.ant-modal-title {
  color: rgba(251, 236, 222) !important;
}

.ant-modal-content {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-menu-item-active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}


* .ant-drawer-content {
  background: rgba(0, 0, 0, 0.6) !important;
}
